import React from "react";
import "./PageLayout.scss";

interface PageLayoutProps {
  children: any;
}
export const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
  return (
    <div className="d-flex flex-grow flex-grow-1">
      <div className="page-layout-content">{children}</div>
    </div>
  );
};
