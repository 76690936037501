import { Button, Form, Modal } from "react-bootstrap";
import InputText from "../../common/components/InputText/InputText";
import { useState } from "react";
import useAxios from "../../common/auth/useAxios";
import Dropdown from "../../common/components/DropDown/DropDown";

const triggerTypes = [
  { label: "Ingen alarm", value: 0, id: "0" },
  { label: "Når verdi er over", value: 1, id: "1" },
  { label: "Når verdi er under", value: 2, id: "2" },
];

type MeasurementAlarmModalProps = {
  show: boolean;
  onHide: any;
  companyId: number;
  deviceId: number;
};

const MeasurementAlarmModal: React.FC<MeasurementAlarmModalProps> = ({
  show,
  onHide,
  companyId,
  deviceId,
}) => {
  const [selectedTriggerTypeId, setSelectedTriggerTypeId] = useState<number>(0);
  const [selectedTriggerType, setSelectedTriggerType] = useState<any>(0);
  const [selectedMessageType, setSelectedMessageType] = useState<number>(0);
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [resendInterval, setResendInterval] = useState<number>(0);
  const [triggerValue, setTriggerValue] = useState<number>(0);

  const { post } = useAxios();

  function handleSubmit(event: any) {
    event.preventDefault();
    event.stopPropagation();
    try {
      post("alarm", {
        id: deviceId ?? 0,
        companyId: companyId,
        triggerType: selectedTriggerTypeId,
        // messageType: selectedMessageType,
        email: email,
        // phoneNumber: phoneNumber,
        resendInterval: resendInterval,
        triggerValue: triggerValue,
      }).then((response: any) => {
        console.log(response);
        onHide();
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Legg til ny alarm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown
            items={triggerTypes}
            onChange={(opt) => {
              setSelectedTriggerTypeId(opt.value);
              setSelectedTriggerType(opt);
              console.log(opt.value);
            }}
            placeholder="Ingen alarm"
            value={selectedTriggerType}
          />
          <InputText
            value={triggerValue}
            onChange={(e: any) => setTriggerValue(e.target.value)}
            inputTitle="Alarm nivå"
            label={true}
          />
          <InputText
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
            inputTitle="Mottaker epost"
            label={true}
          />
          {/* <InputText
            value={phoneNumber}
            onChange={(e: any) => setPhoneNumber(e.target.value)}
            inputTitle="Mottaker telefon"
            label={true}
          /> */}
          <InputText
            value={resendInterval}
            onChange={(e: any) => setResendInterval(e.target.value)}
            inputTitle="Antall timer til ny påminnelse"
            label={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Lagre</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default MeasurementAlarmModal;
