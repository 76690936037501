import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import FormControl from "react-bootstrap/FormControl";
import { useAuth0 } from "@auth0/auth0-react";
import { useStoreActions, useStoreState } from "../../../../store/store";
import Dropdown from "../../DropDown/DropDown";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faProjectDiagram,
  faMapMarkedAlt,
  faUsersCog,
  faCog,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import "./SideBar.scss";
import LogoutButton from "../../Logout/LogoutButton";

function SidebarMenu() {
  const { user, isAuthenticated } = useAuth0();
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const userData = useStoreState((state) => state.account.user);
  const companies = useStoreState((state) => state.account.companies);
  const currentSelectedCompany = useStoreState(
    (state) => state.account.selectedCompany
  );
  const setCurrentSelectedCompany = useStoreActions(
    (state) => state.account.setSelectedCompany
  );

  const companyItems = companies?.map((company, i) => {
    return { label: company.name, value: company.id, id: "" + i };
  });

  useEffect(() => {
    if (currentSelectedCompany != null) {
      var current = companyItems?.find(
        (company) => company.value === currentSelectedCompany.id
      );
      setSelectedCompany(current);
      setSelectedCompanyId(currentSelectedCompany.id);
    }
  }, [currentSelectedCompany]);

  function setCompany(selectedId: number) {
    var selected = companies?.find((company) => company.id === selectedId);
    if (selected != null) {
      setCurrentSelectedCompany(selected);
    }
  }

  if (!isAuthenticated) {
    return null;
  }

  const name = user?.name;
  return (
    <div className="sidebar-container">
      <div className="sidebar-header">
        <div className="company-dropdown">
          {companyItems && companyItems?.length > 1 && (
            <Dropdown
              items={companyItems}
              onChange={(opt) => {
                setSelectedCompanyId(opt.value);
                setSelectedCompany(opt);
                setCompany(opt.value);
                console.log(opt.value);
              }}
              placeholder="Velg selskap"
              value={selectedCompany}
            />
          )}
          {companyItems && companyItems?.length == 1 && (
            <span className="single-company-label">
              <b>{companyItems[0].label}</b>
            </span>
          )}
        </div>
      </div>

      <div className="sidebar-navigation">
        <ul className="nav flex-column">
          {userData?.accessType === 9 && (
            <li className="nav-item">
              <Link to="/admin" className="nav-link">
                <FontAwesomeIcon icon={faUsersCog} /> Admin
              </Link>
            </li>
          )}
          <li className="nav-item">
            <Link to="/dashboard" className="nav-link">
              <FontAwesomeIcon icon={faTachometerAlt} /> Dashbord
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/projects" className="nav-link">
              <FontAwesomeIcon icon={faProjectDiagram} /> Prosjekt
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/areas" className="nav-link">
              <FontAwesomeIcon icon={faMapMarkedAlt} /> Lokasjoner
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/users" className="nav-link">
              <FontAwesomeIcon icon={faUsers} /> Brukere
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link to="/settings" className="nav-link">
              <FontAwesomeIcon icon={faCog} /> Innstillinger
            </Link>
          </li> */}
        </ul>
      </div>

      <div className="sidebar-footer">
        <div className="user-profile">
          <span className="fs-6 text-center">{name}</span>
          <div className="d-flex flex-row px-2 mt-2 justify-content-center">
            {isAuthenticated && <LogoutButton />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SidebarMenu;
