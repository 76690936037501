import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout } = useAuth0();
  const logoutUrl = process.env.REACT_APP_AUTH0_CALLBACK_URL;
  return (
    <button
      className="btn btn-block btn-primary"
      style={{ color: "white", width: "70%" }}
      onClick={
        () => logout({ logoutParams: { returnTo: logoutUrl } }) //returnTo: window.location.origin,
      }
    >
      Log Out
    </button>
  );
};

export default LogoutButton;
